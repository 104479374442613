<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
  id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo" routerLink="/home">
      <img src="assets/images/pisicloud.png" alt="" class="logo-dark" height="30" />
    </a>

    <button class="btn-mobile" (click)="goToDemo()">
      Jadwalkan Demo
    </button>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
        <li class="nav-item dropdown" style="position: static;">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-expanded="false">
            {{'header.feature' | translate}}
          </a>
          <div class="dropdown-menu feature-menu">
            <div class="container">
              <div class="row mb-3" style="margin-right: -120px;">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-account-edit icon-feautre-1"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" routerLink="/recruitment">
                        <h5>
                          <div innerHTML="{{'header.recruitment' | translate}}"></div>
                        </h5>
                        <div innerHTML="{{'header.recruitmentSub' | translate}}"></div>
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-alarm icon-feautre-2"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" routerLink="/attendance-software">
                        <h5>
                          <div innerHTML="{{'header.attendanceSoftware' | translate}}"></div>
                        </h5>
                        <div innerHTML="{{'header.attendanceSoftware' | translate}}"></div>
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-bank icon-feautre-3"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" routerLink="/payroll-software">
                        <h5>
                          <div innerHTML="{{'header.payrollSoftware' | translate}}"></div>
                        </h5>
                        <div innerHTML="{{'header.payrollSoftwareSub' | translate}}"></div>
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-database icon-feautre-4"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" routerLink="/database-management">
                        <h5>
                          <div innerHTML="{{'header.databaseManagement' | translate}}"></div>
                        </h5>
                        <div innerHTML="{{'header.databaseManagementSub' | translate}}"></div>
                      </a>
                    </div>
                  </div>

                </div>

                <div class="col-md-4">
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-calendar icon-feautre-5"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" routerLink="/leave-personal">
                        <h5>
                          <div innerHTML="{{'header.leavePersonal' | translate}}"></div>
                        </h5>
                        <div innerHTML="{{'header.leavePersonalSub' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-calendar-check icon-feautre-6"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" routerLink="/ot-personal">
                        <h5>
                          <div innerHTML="{{'header.otPersonal' | translate}}"></div>
                        </h5>
                        <div innerHTML="{{'header.otPersonalSub' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-account-convert icon-feautre-7"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" routerLink="/employee-update">
                        <h5>
                          <div innerHTML="{{'header.employeeUpdate' | translate}}"></div>
                        </h5>
                        <div innerHTML="{{'header.employeeUpdateSub' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-cellphone icon-feautre-8"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" routerLink="/attendance-personal">
                        <h5>
                          <div innerHTML="{{'header.attendancePersonal' | translate}}"></div>
                        </h5>
                        <div innerHTML="{{'header.attendancePersonalSub' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-calendar-multiple icon-feautre-9"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" routerLink="/leave-submission">
                        <h5>
                          <div innerHTML="{{'header.leaveSubmission' | translate}}"></div>
                        </h5>
                        <div innerHTML="{{'header.leaveSubmissionSub' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-calendar-multiple-check icon-feautre-10"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" routerLink="/ot-submission">
                        <h5>
                          <div innerHTML="{{'header.otSubmission' | translate}}"></div>
                        </h5>
                        <div innerHTML="{{'header.otSubmissionSub' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-lan-connect icon-feautre-11"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" routerLink="/attendance-machine">
                        <h5>
                          <div innerHTML="{{'header.attendanceMachine' | translate}}"></div>
                        </h5>
                        <div innerHTML="{{'header.attendanceMachineSub' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-notebook icon-feautre-12"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" routerLink="/yearly-tax">
                        <h5>
                          <div innerHTML="{{'header.yearlyTax' | translate}}"></div>
                        </h5>
                        <div innerHTML="{{'header.yearlyTaxSub' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-expanded="false">
            {{'header.solution' | translate}}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li [ngClass]="{'disabled': disableList}">
              <a class="dropdown-item" routerLink="/manufacture-companies">
                <i class="mdi mdi-factory h3 mr-3" style="color: #3e87db;"></i> <strong [ngClass]="{'ml-3': isMobile}"
                  class="font-size-17"><b>{{'header.manufactureCompany' | translate}}</b></strong> <br>
                <span *ngIf="!isMobile" class="font-size-14">
                  {{'header.manufactureCompanySub' | translate}}
                </span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li [ngClass]="{'disabled': disableList}">
              <a class="dropdown-item" routerLink="/construction-companies">
                <i class="mdi mdi-crane h2 mr-3" style="color: #46978d"></i> <strong [ngClass]="{'ml-3': isMobile}"
                  class="font-size-17">{{'header.constructionCompany' | translate}}</strong> <br>
                <span *ngIf="!isMobile" class="font-size-14">
                  {{'header.constructionCompanySub' | translate}}
                </span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li [ngClass]="{'disabled': disableList}">
              <a class="dropdown-item" routerLink="/distribution-companies">
                <i class="mdi mdi-warehouse h3 mr-3" style="color: #fd9644"></i> <strong [ngClass]="{'ml-3': isMobile}"
                  class="font-size-17">{{'header.distributorCompany' | translate}}</strong> <br>
                <span *ngIf="!isMobile" class="font-size-14">
                  {{'header.distributorCompanySub' | translate}}
                </span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li [ngClass]="{'disabled': disableList}">
              <a class="dropdown-item" routerLink="/service-companies">
                <i class="mdi mdi-hammer-screwdriver h3 mr-3" style="color: #6557d6"></i> <strong
                  [ngClass]="{'ml-3': isMobile}" class="font-size-17">{{'header.serviceCompany' | translate}}</strong> <br>
                <span *ngIf="!isMobile" class="font-size-14">
                  {{'header.serviceCompanySub' | translate}}
                </span>
              </a>
            </li>
          </ul>
        </li> -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-expanded="false">
            {{'header.resources' | translate}}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" routerLink="/about">
                <i class="mdi mdi-book-open-page-variant h3 mr-3" style="color: #fd9644"></i> <strong
                  [ngClass]="{'ml-3': isMobile}" class="font-size-17">{{'header.about' | translate}}</strong>
                <br>
                <span *ngIf="!isMobile" class="font-size-14">{{'header.aboutSub' | translate}}</span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" routerLink="/implementation-strategy">
                <i class="mdi mdi-sign-text h3 mr-3" style="color: #46978d"></i> <strong [ngClass]="{'ml-3': isMobile}"
                  class="font-size-17">{{'header.strategy' | translate}}</strong>
                <br>
                <span *ngIf="!isMobile" class="font-size-14">{{'header.strategySub' | translate}}</span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" routerLink="/clients">
                <i class="mdi mdi-account-group h3 mr-3" style="color: #3e87db;"></i> <strong
                  [ngClass]="{'ml-3': isMobile}" class="font-size-17">{{'header.customer' | translate}}</strong> <br>
                <span *ngIf="!isMobile" class="font-size-14">{{'header.customerSub' | translate}}
                  &nbsp;&nbsp;</span>
              </a>
            </li>
          </ul>
        </li>
        <!-- <li class="nav-item">
          <a routerLink="/pricing" class="nav-link">{{'header.price' | translate}}</a>
        </li> -->
        <li class="nav-item">
          <a routerLink="/contact" class="nav-link">{{'header.contact' | translate}}</a>
        </li>
        <li class="line"></li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-expanded="false" style="border-bottom: none;">
            <span *ngIf="savedLang === 'id' || !savedLang">
              <img src="assets/images/indonesia-flags.png" width="13%" class="mr-2"> Indonesia
            </span>
            <span *ngIf="savedLang === 'en'">
              <img src="assets/images/us-flag.png" width="15%" class="mr-2"> English
            </span>
            <span *ngIf="savedLang === 'cn'">
              <img src="assets/images/china-flag.png" width="17%" class="mr-2"> 中国
            </span>
            <span *ngIf="savedLang === 'jp'">
              <img src="assets/images/japan-flag.ico" width="20%" class="mr-2"> 日本
            </span>
            <span *ngIf="savedLang === 'kr'">
              <img src="assets/images/korea-flag.ico" width="20%" class="mr-2"> 日本
            </span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li class="flag" (click)="onChangeLang('id')"
              *ngIf="savedLang === 'en' || savedLang === 'cn' || savedLang === 'jp' || savedLang === 'kr' || !savedLang">
              <a class="dropdown-item"> <strong [ngClass]="{'ml-3': isMobile}" class="font-size-15">
                  <img src="assets/images/indonesia-flags.png" width="13%" class="mr-2"> Indonesia
                </strong>
                <br>
              </a>
            </li>
            <li class="flag" (click)="onChangeLang('en')"
              *ngIf="savedLang === 'id' || savedLang === 'cn' || savedLang === 'jp' || savedLang === 'kr' || !savedLang">
              <a class="dropdown-item"> <strong [ngClass]="{'ml-3': isMobile}" class="font-size-15">
                  <img src="assets/images/us-flag.png" width="13%" class="mr-2"> English</strong>
                <br>
              </a>
            </li>
            <li class="flag" (click)="onChangeLang('cn')"
              *ngIf="savedLang === 'id' || savedLang === 'en' || savedLang === 'jp' || savedLang === 'kr' || !savedLang">
              <a class="dropdown-item"> <strong [ngClass]="{'ml-3': isMobile}" class="font-size-15">
                  <img src="assets/images/china-flag.png" width="13%" class="mr-2"> 中国 </strong>
                <br>
              </a>
            </li>
            <li class="flag" (click)="onChangeLang('jp')"
              *ngIf="savedLang === 'id' || savedLang === 'en' || savedLang === 'cn' || savedLang === 'kr' || !savedLang">
              <a class="dropdown-item"> <strong [ngClass]="{'ml-3': isMobile}" class="font-size-15">
                  <img src="assets/images/japan-flag.ico" width="13%" class="mr-2"> 日本 </strong>
                <br>
              </a>
            </li>
            <li class="flag" (click)="onChangeLang('kr')"
              *ngIf="savedLang === 'id' || savedLang === 'en' || savedLang === 'cn' || savedLang === 'jp' || !savedLang">
              <a class="dropdown-item"> <strong [ngClass]="{'ml-3': isMobile}" class="font-size-15">
                  <img src="assets/images/korea-flag.ico" width="13%" class="mr-2"> 한국 </strong>
                <br>
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="navbar-nav navbar-center">
        <li class="nav-item">
          <button class="btn btn-primary btn-sm btn-pc" (click)="goToDemo()">
            {{'header.demo' | translate}}
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- Navbar End -->