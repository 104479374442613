<section class="section" style="background-image: url(assets/images/bg/7.png)">
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <div innerHTML="{{'price.title' | translate}}"></div>
          <p class="font-size-16">{{'price.subtitle' | translate}}</p>
          <hr style="border: 3px solid #1c735a; width: 40%;">
        </div>
      </div>
    </div>
    <div class="price-plan-wrapper">
      <div class="row">
        <div class="col-lg-5 offset-lg-1 col-md-6">
          <div class="pricing-table-8">
            <div class="price-header">
              <h3 class="title">BASIC</h3>
              <div class="price">
                <span class="dollar">Rp</span>250.000
                <div class="month">Cloud Service/User/{{'price.month' | translate}}</div>
              </div>
            </div>
            <div class="container font-weight-bold text-left font-size-13">
              <hr>
              <div class="text-center">
                <span class="font-size-14 font-weight-normal">{{'price.addUser' | translate}} <br> <b>Rp
                    100.000</b></span>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> General Ledger</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Account Payable</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i>Account Receivable</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Fixed
                    Asset</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Cash & Bank</span>
                </div>
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Procurement</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Service</span>
                </div>
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Inventory</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Sales</span>
                </div>
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Assembly</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Budgeting</span>
                </div>
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Dashboard</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Bill of Material &
                    Routing</span>
                </div>
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Production
                    Module</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Construction
                    Module</span>
                </div>
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Project Costing</span>
                </div>
              </div>
              <hr>
              <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Standart Costing</span>
              <hr>
            </div>
            <span>{{'price.salary' | translate}} <br> <b>Rp 20.000.000</b></span>
            <div class="mt-3">
              <b>Full Software <span class="text-primary">Maintenance Services</span></b>
            </div>
            <div class="price-footer">
              <a class="order-btn"
                href="https://api.whatsapp.com/send?phone=628117774744&text=Hello%20Inforsys%2C%20saya%20tertarik%20dengan%20produk%20Inforsys%20%2C%20bisakah%20dijelaskan%20lebih%20lanjut%3F">Contact
                Us</a>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="pricing-table-8">
            <div class="price-header">
              <h3 class="title">PROFESSIONAL</h3>
              <div class="price">
                <span class="dollar">Rp</span>400.000
                <div class="month">Cloud Service/User/{{'price.month' | translate}}</div>
              </div>
            </div>
            <div class="container font-weight-bold text-left font-size-13">
              <hr>
              <div class="text-center">
                <span class="font-size-14 font-weight-normal">{{'price.addUser' | translate}} <br> <b>Rp
                    200.000</b></span>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> General Ledger</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Account Payable</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i>Account Receivable</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Fixed
                    Asset</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Cash & Bank</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Procurement</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Service</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Inventory</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Sales</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Assembly</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Budgeting</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Dashboard</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Bill of Material &
                    Routing</span>
                </div>
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Production
                    Module</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Construction
                    Module</span>
                </div>
                <div class="col-xl-6">
                  <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Project Costing</span>
                </div>
              </div>
              <hr>
              <span class="soft-color"><i class="mdi mdi-close-circle mr-1 text-danger"></i> Standart Costing</span>
              <hr>
            </div>
            <span>{{'price.salary' | translate}} <br> <b>Rp 50.000.000</b></span>
            <div class="mt-3">
              <b>Full Software <span class="text-primary">Maintenance Services</span></b>
            </div>
            <div class="price-footer">
              <a class="order-btn"
                href="https://api.whatsapp.com/send?phone=628117774744&text=Hello%20Inforsys%2C%20saya%20tertarik%20dengan%20produk%20Inforsys%20%2C%20bisakah%20dijelaskan%20lebih%20lanjut%3F">Contact
                Us</a>
            </div>
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1  col-md-6">
          <div class="pricing-table-8 active">
            <div class="price-header">
              <span class="popular">Best</span>
              <h3 class="title">ENTERPRISE</h3>
              <div class="price">
                <span class="dollar">Rp</span>500.000
                <div class="month">Cloud Service/User/{{'price.month' | translate}}</div>
              </div>
            </div>
            <div class="container font-weight-bold text-left font-size-13">
              <hr>
              <div class="text-center">
                <span class="font-size-14 font-weight-normal">{{'price.addUser' | translate}} <br> <b>Rp
                    350.000</b></span>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> General Ledger</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Account Payable</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i>Account Receivable</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Fixed
                    Asset</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Cash & Bank</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Procurement</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Service</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Inventory</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Sales</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Assembly</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Budgeting</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Dashboard</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Bill of Material & Routing</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Production Module</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Construction Module</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Project Costing</span>
                </div>
              </div>
              <hr>
              <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Standart Costing</span>
              <hr>
            </div>
            <span>{{'price.salary' | translate}} <br> <b>Rp 125.000.000</b></span>
            <div class="mt-3">
              <b>Full Software <span class="text-primary">Maintenance Services</span></b>
            </div>
            <div class="price-footer">
              <a class="order-btn"
                href="https://api.whatsapp.com/send?phone=628117774744&text=Hello%20Inforsys%2C%20saya%20tertarik%20dengan%20produk%20Inforsys%20%2C%20bisakah%20dijelaskan%20lebih%20lanjut%3F">Contact
                Us</a>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="pricing-table-8">
            <div class="price-header">
              <h3 class="title">SPECIAL PACKAGE</h3>
              <div class="price">
                {{'header.contact' | translate}}
                <div class="month">Cloud Service/User/{{'price.month' | translate}}</div>
              </div>
            </div>
            <div class="container font-weight-bold text-left font-size-13">
              <hr>
              <div class="text-center">
                <span class="font-size-14 font-weight-normal">{{'price.addUser' | translate}} <br> <b>{{'header.contact'
                    | translate}}</b></span>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> General Ledger</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Account Payable</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i>Account Receivable</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Fixed
                    Asset</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Cash & Bank</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Procurement</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Service</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Inventory</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Sales</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Assembly</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Budgeting</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Dashboard</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Bill of Material & Routing</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Production Module</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Construction Module</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Project Costing</span>
                </div>
              </div>
              <hr>
              <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Standart Costing</span>
              <hr>
            </div>
            <span>{{'price.salary2' | translate}} <br> <b>{{'header.contact' | translate}}</b></span>
            <div class="mt-3">
              <b>++ Full <span class="text-primary">Customize Module</span></b>
            </div>
            <div class="price-footer">
              <a class="order-btn"
                href="https://api.whatsapp.com/send?phone=628117774744&text=Hello%20Inforsys%2C%20saya%20tertarik%20dengan%20produk%20Inforsys%20%2C%20bisakah%20dijelaskan%20lebih%20lanjut%3F">Contact
                Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>