<div class="section feather-bg-img" style="background-image: url(assets/images/bg/6.png);" id="features">
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-5">
        <div class="mb-5 mt-3">
          <p class="font-weight-bold">
            <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> {{'yearlyTax.header' | translate}}
          </p>
          <hr style="margin-top: -10px;">
          <h1>{{'yearlyTax.title' | translate}}</h1>
          <p class="font-size-16">
            {{'yearlyTax.p1' | translate}}
          </p>
        </div>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-6 mt-5 img-feature">
        <img src="assets/images/features/tax 1.png" class="d-block img-fluid">
      </div>
    </div>

  </div>
</div>


<section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img.png)">
  <div class="container" style="margin-top: -7em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/features/tax 2.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <p class="mb-2 font-size-15">
          {{'yearlyTax.p2' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img-1.png)">
  <div class="container" style="margin-top: -5em;">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <p class="mb-2 font-size-15">
          {{'yearlyTax.p3' | translate}}
        </p>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/features/tax 3.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img.png)">
  <div class="container" style="margin-top: -5em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/features/tax 4.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">

        <div innerHTML="{{'yearlyTax.p4' | translate}}"></div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img-1.png)">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div innerHTML="{{'yearlyTax.p5' | translate}}"></div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/features/tax 5.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img.png)">
  <div class="container" style="margin-top: -5em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/features/tax 6.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">

        <div innerHTML="{{'yearlyTax.p6' | translate}}"></div>
      </div>
    </div>
  </div>
</section>