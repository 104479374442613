<!-- Footer Start -->
<div class="footer" style="background-image: url(assets/images/footer-bg.png)">
  <div class="container">
    <div class="row">
      <div class="col-lg-4" [ngClass]="{'mb-5': isMobile}">
        <img src="assets/images/inforsys-white.png" alt="" class="mb-4" height="35" width="53%">
        <div class="row text-white mb-3">
          <i class="mdi mdi-map-marker h5" style="margin-left: 12px;"> </i>
          <div class="col-lg-10">
            <span style="font-size: 15px;">PT Inforsys Indonesia <br> Orchard Park Blok C No. 18 Agung Podomoro Land
              Batam,
              Kepulauan Riau 29464 Indonesia</span>
          </div>
        </div>

        <div class="row text-white mb-3">
          <i class="mdi mdi-phone h5" style="margin-left: 12px;"> </i>
          <div class="col-lg-10">
            <span style="font-size: 15px;">(+62) 778 416 0250</span>
          </div>
          <i class="mdi mdi-whatsapp h5" style="margin-left: 12px;"> </i>
          <div class="col-lg-10">
            <span style="font-size: 15px;">(+62) 811 7774 744</span>
          </div>
          <i class="mdi mdi-email-outline h5" style="margin-left: 12px;"> </i>
          <div class="col-lg-10">
            <span style="font-size: 15px;">hello@inforsys.co.id</span>
          </div>
        </div>
      </div>

      <div class="col-lg-3" [ngClass]="{'mb-5': isMobile}">
        <h6 class="text-white text-uppercase mb-4">{{ 'footer.tabAbout' | translate}}</h6>
        <ul class="list-unstyled footer-sub-menu">
          <li><a href="about" class="footer-link li a">{{ 'footer.aboutPISICloud' | translate}}</a></li>
          <li><a href="https://inforsys.co.id/about" class="footer-link li a" target="_blank">{{ 'footer.aboutInforsys'
              |
              translate}}</a></li>
          <li><a href="terms-conditions" class="footer-link li a">{{ 'footer.toc' | translate}}</a></li>
          <li><a href="privacy" class="footer-link li a">{{ 'footer.privacy' | translate}}</a></li>
          <li><a href="pernyataan-resmi-inforsys-terkait-covid-19" class="footer-link li a">{{ 'footer.covid19' |
              translate}}</a></li>
        </ul>
      </div>

      <div class="col-lg-2" [ngClass]="{'mb-5': isMobile}">
        <h6 class="text-white text-uppercase mb-3">{{ 'footer.tabCustomer' | translate}}</h6>
        <ul class="list-unstyled footer-sub-menu">
          <li><a href="manufacture-companies" class="footer-link li a">{{ 'footer.manufacture' | translate}}</a></li>
          <li><a href="distribution-companies" class="footer-link li a">{{ 'footer.distributor' | translate}}</a></li>
          <li><a href="construction-companies" class="footer-link li a">{{ 'footer.construction' | translate}}</a></li>
          <li><a href="service-companies" class="footer-link li a">{{ 'footer.service' | translate}}</a></li>
        </ul>
      </div>

      <div class="col-lg-3">
        <h6 class="text-white text-uppercase mb-3">{{ 'footer.tabSupport' | translate}}</h6>
        <ul class="list-unstyled footer-sub-menu">
          <li><a href="strategy-Implementasi" class="footer-link li a">{{ 'footer.implementation' | translate}}</a></li>
          <li><a href="training" class="footer-link li a">{{ 'footer.training' | translate}}</a></li>
          <li><a href="annual-maintenance" class="footer-link li a">{{ 'footer.maintenance' | translate}}</a></li>
          <li><a href="customize-module" class="footer-link li a">{{ 'footer.customize' | translate}}</a></li>
        </ul>
        <br>
        <div>
          <a href="https://pse.kominfo.go.id/home" target="_blank" >
            <img src="assets/icons/pse-terdaftar.png" class="icon-pse">
          </a>
          <ul class="wrapper list-inline footer-social-icon-content">
            <li class="icon facebook list-inline-item">
              <span class="tooltip">Facebook</span>
              <a href="https://id-id.facebook.com/inforsys.co.id/" target="_blank" class="footer-social-icon">
                <img src="assets/icons/facebook.png" class="icon-sosmed">
              </a>
            </li>
            <li class="icon twitter list-inline-item">
              <span class="tooltip">Twitter</span>
              <a href="https://twitter.com/inforsys_batam" target="_blank" class="footer-social-icon">
                <img src="assets/icons/twitter.png" class="icon-sosmed">
              </a>
            </li>
            <li class="icon instagram list-inline-item">
              <span class="tooltip">Instagram</span>
              <a href="https://www.instagram.com/inforsys.co.id/" target="_blank" class="footer-social-icon">
                <img src="assets/icons/instagram.png" class="icon-sosmed">
              </a>
            </li>
            <li class="icon linkedin list-inline-item">
              <span class="tooltip">Linkedin</span>
              <a href="https://www.linkedin.com/company/pt-inforsys-indonesia-tbk-/mycompany/" target="_blank"
                class="footer-social-icon">
                <img src="assets/icons/linkedin.png" class="icon-sosmed">
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer-alt py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <p class="text-white-50 font-size-15 mb-0">{{year}} © PT Inforsys Indonesia</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Footer End -->