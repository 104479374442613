<section class="hero-4-bg" style="background-image: url(assets/images/bg/6.1.png)">
  <div class="section feather-bg-img">
    <div class="container mt-5">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <h2 class="mb-3">
            <div innerHTML="{{'customer.title' | translate}}"></div>
          </h2>
          <p class="mb-5 pb-5 font-size-17">
            {{'customer.content' | translate}}
          </p>
        </div>
        <div class="col-lg-5 offset-lg-1">
          <img src="assets/images/customer.png" class="w-100">
        </div>
      </div>
    </div>
  </div>
  <!-- Hero Start -->
  <div id="home">
    <div class="container" style="margin-top: -50px;">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="text-center mb-5">
            <h2>PISICloud <span class="text-primary">Customer</span></h2>
          </div>
          <hr style="border: 3px solid #1c735a; margin-top: -24px; width: 5em;">
        </div>
      </div>
      <br>
      <div class="pc align-items-center mt-3">
        <div class="row mb-5 logo">
          <div class="col-md-2">
            <img src="assets/images/customer/1.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/2.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/3.png" style="width: 120px;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/4.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/5.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/6.png">
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-2">
            <img src="assets/images/customer/7.png" style="width: 55%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/8.png" style="width:130px;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/9.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/10.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/11.png" style="width: 75%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/12.jpg" style="width:120px;">
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-2">
            <img src="assets/images/customer/13.png" style="width:75%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/14.png" style="width:75%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/15.png" style="width: 70%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/16.png" style="width: 70%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/17.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/18.png" style="width: 95%;">
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-2">
            <img src="assets/images/customer/19.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/20.png" style="width:95%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/21.png" style="width: 95%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/22.jpg">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/23.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/24.png" style="width: 95%;">
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-2">
            <img src="assets/images/customer/25.png" style="width: 95%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/26.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/27.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/28.png" style="width: 95%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/29.png" style="width: 95%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/30.png">
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-2">
            <img src="assets/images/customer/31.png" style="width: 70%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/32.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/33.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/34.png" style="width: 60%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/35.png" style="width:60%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/36.png" style="width: 80%;">
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-2">
            <img src="assets/images/customer/37.png" style="width: 70%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/38.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/39.png" style="width: 95%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/40.png" style="width: 70%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/41.png" style="width:95%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/42.png" style="width: 95%;">
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-2">
            <img src="assets/images/customer/43.png" style="width: 95%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/44.png" style="width: 75%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/45.png">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/46.png" style="width: 95%;">
          </div>
          <!-- <div class="col-md-2">
            <img src="assets/images/customer/47.jpeg" style="width: 65%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/48.jpeg" style="width: 55%;">
          </div> -->
        </div>

        <!-- <div class="row mb-5">
          <div class="col-md-2">
            <img src="assets/images/customer/49.jpeg" style="width: 70%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/50.jpeg" style="width: 70%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/51.jpeg" style="width: 70%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/52.jpeg" style="width: 70%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/53.jpeg" style="width: 100%;">
          </div>
          <div class="col-md-2">
            <img src="assets/images/customer/54.jpeg" style="width: 60%;">
          </div>
        </div> -->
      </div>

      <div class="mobile align-items-center mt-3">
        <div class="text-center mb-5">
          <img src="assets/images/customer/1.png" class="mr-5">
          <img src="assets/images/customer/2.png">
        </div>
        <div class="text-center mb-5">
          <img src="assets/images/customer/3.png" class="mr-5">
          <img src="assets/images/customer/4.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/5.png" class="mr-5">
          <img src="assets/images/customer/6.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/7.png" class="mr-5">
          <img src="assets/images/customer/8.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/9.png" class="mr-5">
          <img src="assets/images/customer/10.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/11.png" class="mr-5">
          <img src="assets/images/customer/12.jpg">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/13.png" class="mr-5">
          <img src="assets/images/customer/14.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/15.jpeg" class="mr-5">
          <img src="assets/images/customer/16.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/17.png" class="mr-5">
          <img src="assets/images/customer/18.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/19.jpg" class="mr-5">
          <img src="assets/images/customer/20.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/21.png" class="mr-5">
          <img src="assets/images/customer/22.jpg">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/23.jpeg" class="mr-5">
          <img src="assets/images/customer/24.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/25.png" class="mr-5">
          <img src="assets/images/customer/26.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/27.jpeg" class="mr-5">
          <img src="assets/images/customer/28.jpg">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/29.jpg" class="mr-5">
          <img src="assets/images/customer/30.jpg">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/31.png" class="mr-5">
          <img src="assets/images/customer/32.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/33.jpg" class="mr-5">
          <img src="assets/images/customer/34.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/35.jpg" class="mr-5">
          <img src="assets/images/customer/36.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/37.jpeg" class="mr-5">
          <img src="assets/images/customer/38.jpeg">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/39.jpeg" class="mr-5">
          <img src="assets/images/customer/40.jpeg">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/41.jpg" class="mr-5">
          <img src="assets/images/customer/42.jpeg">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/43.png" class="mr-5">
          <img src="assets/images/customer/44.jpeg">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/45.png" class="mr-5">
          <img src="assets/images/customer/46.png">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/47.jpeg" class="mr-5">
          <img src="assets/images/customer/48.jpeg">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/49.jpeg" class="mr-5">
          <img src="assets/images/customer/50.jpeg">
        </div>

        <div class="text-center mb-5">
          <img src="assets/images/customer/51.jpeg" class="mr-5">
          <img src="assets/images/customer/52.jpeg">
        </div>

        <div class="text-center">
          <img src="assets/images/customer/53.jpeg" class="mr-5">
          <img src="assets/images/customer/54.jpeg">
        </div>
      </div>

    </div>
  </div>
</section>

<!-- <app-testimonial></app-testimonial> -->