<section class="hero-4-bg" style="background-image: url(assets/images/bg/6.3.png)" id="home">
  <div class="container">
    <div class="text-center mb-5" style="margin-top: -5em;">
      <div innerHTML="{{'about.start' | translate}}"></div>
      <hr style="border: 2px solid #1c735a; width: 20%; margin-top: -10px;">
    </div>
    <br><br>
    <section class="mb-4">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-5 col-12">
            <div class="mb-4 mb-lg-0">
              <img src="assets/images/about-us.png" class="img-fluid w-100">
            </div>
          </div>
          <div class="col-lg-6">
            <div innerHTML="{{'about.content1' | translate}}"></div>
            <p class="font-size-16 mb-4">
              {{'about.content1Sub' | translate}}
            </p>
          </div>
        </div>
      </div>
    </section>

    <br><br><br>

    <section class="mb-4">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 offset-lg-1" *ngIf="isMobile">
            <div class="mt-3">
              <img src="assets/images/about-us2.png" class="img-fluid w-100">
            </div>
          </div>
          <div class="col-lg-6">
            <div innerHTML="{{'about.content2' | translate}}"></div>
            <p class="font-size-16 mb-4">
              {{'about.content2Sub' | translate}}
            </p>
          </div>
          <div class="col-lg-5 offset-lg-1" *ngIf="!isMobile">
            <div class="mt-3">
              <img src="assets/images/about-us2.jpg" class="img-fluid" style="width: 90%;">
            </div>
          </div>
        </div>
      </div>
    </section>

    <br><br><br>

    <section class="mb-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5" *ngIf="!isMobile">
            <div class="mb-4 mb-lg-0">
              <img src="assets/images/about-us4.png" class="about-us3">
            </div>
          </div>
          <div class="col-lg-4" style="width: 90%;" *ngIf="isMobile">
            <div class="mb-4 mb-lg-0">
              <img src="assets/images/about-us4.png" class="about-us3">
            </div>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <div innerHTML="{{'about.content3' | translate}}"></div>
            <p class="font-size-16 mb-4">
              {{'about.content3Sub' | translate}}
            </p>
          </div>
        </div>
      </div>
    </section>

    <br><br><br>

    <section class="mb-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 offset-lg-1" style="width: 90%;" *ngIf="isMobile">
            <div class="mt-3">
              <img src="assets/images/about-us3.png" class="about-us4">
            </div>
          </div>
          <div class="col-lg-6">
            <div innerHTML="{{'about.content4' | translate}}"></div>
            <p class="font-size-16 mb-4">
              {{'about.content4Sub' | translate}}
            </p>
          </div>
          <div class="col-lg-5 offset-lg-1" *ngIf="!isMobile">
            <div class="mt-3">
              <img src="assets/images/about-us3.png" style="max-width: 140%;">
            </div>
          </div>
        </div>
      </div>
    </section>

    <br><br><br>

    <section>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5">
            <div class="mb-4 mb-lg-0">
              <img src="assets/images/about-us5.png" class="img-fluid w-100">
            </div>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <div innerHTML="{{'about.content5' | translate}}"></div>
            <p class="font-size-16 mb-4">
              {{'about.content5Sub' | translate}}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>

</section>

<section style="background-image: url(assets/images/bg/6.3.png)">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-9 mb-5">
        <div class="mb-5">
          <div innerHTML="{{'about.vision' | translate}}"></div>
          <hr style="border: 2px solid #1c735a; width: 140px;">
          <p class="font-size-17 text-center">
            {{'about.visionSub' | translate}}
          </p>
        </div>
        <div class="mb-5">
          <div innerHTML="{{'about.mission' | translate}}"></div>
          <hr style="border: 2px solid #1c735a; width: 140px;">
          <div innerHTML="{{'about.missionSub' | translate}}"></div>
        </div>
      </div>
    </div>
  </div>
</section>

<section style="background-image: url(assets/images/bg/6.3.png);">
  <div class="container">
    <div class="row justify-content-center mb-5">
      <div class="col-xl-8 text-center mb-5">
        <div class="mb-5">
          <div innerHTML="{{'about.value' | translate}}"></div>
          <hr style="border: 2px solid #1c735a; width: 140px; margin-bottom: 10px;">
        </div>
        <div class="row">
          <div class="col-xl-4 mb-3">
            <p class="mb-4" style="margin-left: -40px;">
              <img src="assets/icons/integrity.png" width="15%" style="margin-right: 10px; margin-bottom: 5px;">
              <b>INTEGRITY</b>
            </p>
          </div>
          <div class="col-xl-4 mb-3">
            <p class="mb-4">
              <img src="assets/icons/innovation.png" width="20%" style="margin-right: 10px; margin-bottom: 5px;">
              <b>INNOVATION</b>
            </p>
          </div>
          <div class="col-xl-4">
            <p>
              <img src="assets/icons/teamwork.png" width="20%" style="margin-right: 10px; margin-bottom: 5px;">
              <b>TEAMWORK</b>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 text-right" [ngClass]="{'communication': isMobile}">
            <p>
              <img src="assets/icons/communication.jpg" width="12%" style="margin-right: 10px; margin-bottom: 5px;">
              <span style="margin-right: 10px;"><b>COMMUNICATION</b></span>
            </p>
          </div>
          <div class="col-xl-6" [ngClass]="{'simplicity': isMobile}">
            <p>
              <img src="assets/icons/simplicity.png" width="15%">
              <b>SIMPLICITY</b>
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>