<section class="hero-1-bg" style="background-image: url(assets/images/bg/6.png)" id="home">
  <div class="container">
    <div class="text-center">
      <h1 class="font-weight-bold mb-4">
        <div innerHTML="{{'solution.manufacture.start' | translate}}"></div>
      </h1>
      <p class="font-size-16">
        {{'solution.manufacture.startSub' | translate}}
      </p>
      <div class="img-menu">
        <img src="assets/images/manufaktur.jpg" alt="" class="img-fluid d-block">
      </div>
    </div>
  </div>
</section>

<div style="margin-top: -2em;">
  <app-block-quote1></app-block-quote1>
</div>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/7.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.manufacture.content1' | translate}}"></div>
        </h3>
        <div innerHTML="{{'solution.manufacture.content1Sub' | translate}}"></div>
      </div>
      <div class="col-lg-7 offset-lg-1">
        <div class="mt-3">
          <img src="assets/images/solution/manufacture.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/solution/manufacture2.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-4 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.manufacture.content2' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.manufacture.content2Sub' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<!-- <app-testimonial></app-testimonial> -->

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.manufacture.content3' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.manufacture.content3Sub' | translate}}
        </p>
      </div>
      <div class="col-lg-7 offset-lg-1">
        <div class="mt-3">
          <img src="assets/images/solution/manufacture3.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>


<section class="section hero-1-bg" style="background-image: url(assets/images/bg/7.png);">
  <div class="container">
    <div class="row align-items-center" style="margin-top: -7em;">
      <div class="col-lg-6">
        <div class="mt-3">
          <img src="assets/images/solution/manufacture4.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.manufacture.content4' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.manufacture.content4Sub' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/7.png)">
  <div class="container text-center" style="margin-top: -3em;">
    <h3 class="font-weight-bold">
      <div innerHTML="{{'solution.manufacture.content5' | translate}}"></div>
    </h3>
    <br>
    <img src="assets/images/solution/manufacture5.png" width="100%" class="img-fluid d-block">
    <p class="font-size-15 mt-5">
      {{'solution.manufacture.content5Sub' | translate}}
    </p>
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/7.png)">
  <div class="container" style="margin-top: -5em;">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.manufacture.content6' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.manufacture.content6Sub' | translate}}
        </p>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="mt-3">
          <img src="assets/images/solution/manufacture6.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container" style="margin-top: -7em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-3">
          <img src="assets/images/solution/manufacture7.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.manufacture.content7' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.manufacture.content7Sub' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<app-block-quote2></app-block-quote2>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/7.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.manufacture.content8' | translate}}"></div>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'solution.manufacture.content8Sub1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'solution.manufacture.content8Sub2' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'solution.manufacture.content8Sub3' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'solution.manufacture.content8Sub4' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'solution.manufacture.content8Sub5' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'solution.manufacture.content8Sub6' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'solution.manufacture.content8Sub7' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="mt-3">
          <img src="assets/images/solution/manufacture8.jpg" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/9.png)">
  <div class="text-center" style="margin-top: -7em;">
    <h2 class="font-weight-bold">Production <span class="text-primary">Analysis Report</span></h2>
  </div>

  <div class="container mt-5">
    <img src="assets/images/solution/manufacture9.png" class="img-fluid d-block">
  </div>
</section>

<section class="section hero-1-bg bg-light" style="background-image: url(assets/images/bg/9.1.png)">
  <div class="text-center" style="margin-top: -7em;">
    <h2 class="font-weight-bold">Inventory <span class="text-primary">Movement Report</span></h2>
  </div>

  <div class="container mt-5">
    <img src="assets/images/solution/manufacture10.png" class="img-fluid d-block">
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/9.png)">
  <div class="text-center" style="margin-top: -7em;">
    <h2 class="font-weight-bold">Accounting <span class="text-primary">Journal</span></h2>
  </div>

  <div class="container mt-5">
    <img src="assets/images/solution/manufacture11.png" class="img-fluid d-block">
  </div>
</section>

<section class="section hero-1-bg bg-light" style="background-image: url(assets/images/bg/9.1.png)">
  <div class="text-center" style="margin-top: -7em;">
    <h2 class="font-weight-bold">Cost of <span class="text-primary">Goods Sold</span></h2>
  </div>

  <div class="container mt-5">
    <img src="assets/images/solution/manufacture12.png" class="img-fluid d-block">
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/9.png)">
  <div class="text-center" style="margin-top: -7em;">
    <h2 class="font-weight-bold">Income <span class="text-primary">Statement</span></h2>
  </div>

  <div class="container mt-5">
    <img src="assets/images/solution/manufacture13.png" class="img-fluid d-block">
  </div>
</section>