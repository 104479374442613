<app-header></app-header>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/7.1.png)">
  <div class="container">
    <div class="row">
      <div class="container">
        <div class="text-center">

          <h1 class="mb-3"><b>{{'annualSoftware.title1' | translate}} <span
                class="text-primary">{{'annualSoftware.title2' | translate}}</span>{{'annualSoftware.title3' |
              translate}}</b></h1>
          <p class="font-size-16">
            {{'annualSoftware.titleSub' | translate}}
          </p>

          <hr style="border: 2px solid #1c735a; width: 13em;">

          <img src="assets/images/software-Maintenance.jpg" alt="" class="img-fluid d-block mx-auto mt-5" width="50%">
        </div>

        <div class="mt-5">
          <p class="font-size-16 mb-4">
            {{'annualSoftware.p1' | translate}}
          </p>
          <ul class="font-size-15 mb-4">
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p1l1' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p1l2' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p1l3' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p1l4' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p1l5' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p1l6' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p1l7' | translate}}
            </li>
          </ul>
          <p class="font-size-15 mb-4">
            {{'annualSoftware.p2' | translate}}
          </p>
          <ul class="font-size-15 mb-4">
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p2l1' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p2l2' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p2l3' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p2l4' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p2l5' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p2l6' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p2l7' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p2l8' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p2l9' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.p2l10' | translate}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>