<section class="section" style="background-image: url(assets/images/bg/company-static.png)">
  <div class="container" style="margin-top: -4em; margin-bottom: -4em;">
    <div class="row text-center">
      <div class="col-xl-3">
        <i class="mdi mdi-calendar"></i>
        <p class="number counter counter-1">1998</p>
        <hr>
        <p class="small-title">{{ 'companyStats.since' | translate}}</p>
      </div>

      <div class="col-xl-3">
        <i class="mdi mdi-account-group"></i>
        <p class="number counter counter-2">15+</p>
        <hr>
        <p class="small-title">{{ 'companyStats.supportTeam' | translate}}</p>
      </div>

      <div class="col-xl-3">
        <i class="mdi mdi-city"></i>
        <p class="number counter counter-3">100+</p>
        <hr>
        <p class="small-title">{{ 'companyStats.customer' | translate}}</p>
      </div>

      <div class="col-xl-3">
        <i class="mdi mdi-account-supervisor"></i>
        <p class="number counter counter-4">1500+</p>
        <hr>
        <p class="small-title">{{ 'companyStats.user' | translate}}</p>
      </div>

    </div>
  </div>
</section>