<div appScrollspy [spiedTags]="['SECTION']">
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/bg/6.png)" id="home">
    <div class="container">
      <div class="row mb-5">
        <div class="text-center">

          <h1 class="font-weight-bold mb-3"> <span class="text-primary">SOFI </span>for Services <span
              class="text-primary">Company</span> </h1>
          <p class="mb-3 font-size-16">
            SOFI for Services Company
          </p>
          <!-- <hr style="border: 2px solid #1c735a; width: 80%; margin-left: -3px;"> -->

          <img src="assets/images/services.jpeg" alt="" class="img-fluid d-block mx-auto" width="70%">
        </div>
      </div>
      <p class="font-size-15 mb-4">

      </p>
      <p class="font-size-15 mb-4">

      </p>
    </div>
  </section>
  <!-- Hero End -->

</div>