<section class="section">
  <div class="container" style="margin-top: -5em; margin-bottom: -5em;">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <div innerHTML="{{'custSlide.title' | translate}}"></div>
          <hr style="border: 2px solid #1c735a; width: 100px;">
          <p class="font-size-16">
            {{'custSlide.subTitle' | translate}}
          </p>
          <a href="/clients" class="text-primary font-weight-semibold">{{'custSlide.more' | translate}} <span
              class="ml-2 right-icon">&#8594;</span></a>
        </div>
      </div>
    </div>
    <div class="row align-items-center h-100">
      <div class="container rounded">
        <div class="slider">
          <div class="logos">
            <img class="fab" src="assets/images/customer/1.png">
            <img class="fab" src="assets/images/customer/2.png">
            <img class="fab" src="assets/images/customer/3.png" style="width: 120px;">
            <img class="fab" src="assets/images/customer/4.png">
            <img class="fab" src="assets/images/customer/5.png">
            <img class="fab" src="assets/images/customer/6.png">
            <img class="fab" src="assets/images/customer/7.png" style="width: 85px;">
            <img class="fab" src="assets/images/customer/8.png" style="width: 150px;">
            <img class="fab" src="assets/images/customer/9.png" style="width: 60px;">
            <img class="fab" src="assets/images/customer/10.png">
            <img class="fab" src="assets/images/customer/11.png" style="width: 150px;">
            <img class="fab" src="assets/images/customer/12.jpg" style="width: 150px;">
            <img class="fab" src="assets/images/customer/13.png">
            <img class="fab" src="assets/images/customer/14.png">
            <img class="fab" src="assets/images/customer/15.png" style="width: 150px;">
            <img class="fab" src="assets/images/customer/16.png">
            <img class="fab" src="assets/images/customer/17.png">
            <img class="fab" src="assets/images/customer/18.png">
            <img class="fab" src="assets/images/customer/19.png">
            <img class="fab" src="assets/images/customer/20.png">
            <img class="fab" src="assets/images/customer/21.png">
            <img class="fab" src="assets/images/customer/22.jpg">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>