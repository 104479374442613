<div class="section bg-light feather-bg-img" style="background-image: url(assets/images/features-bg-img.png);"
  id="features">
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-6">
        <div class="mb-5">
          <h3 class="title text-dark text-shadow mb-3">SOFICloud, Software Akuntansi Handal Dengan Beragam Fitur
            Unggulan </h3>
          <p class="font-size-15">SOFICloud Accounting (Simple ERP) System , dilengkapi dengan berbagai fitur
            untuk
            memudahkan dan mendukung proses pencatatan transaksi keuangan perusahaan berjalan secara lebih efektif dan
            efisien.
            Dengan pengalaman lebih dari 20 tahun mengelola banyak bisnis client Kami dengan beragam bidang usahanya,
            tentunya
            telah melahirkan banyak fitur-fitur unggulan dan handal yang dapat Anda gunakan untuk menunjang kinerja
            bisnis lebih baik lagi
          </p>
        </div>
      </div>
      <div class="col-lg-6">
        <img src="assets/images/feature.svg" style="width: 100%;">
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-4">
            <div class="wc-box rounded text-center wc-box-primary p-4 mt-md-5">
              <div class="wc-box-icon">
                <i class="mdi mdi-clipboard"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Laporan Analisa Transaksi dan Keuangan Lengkap</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Membantu menghasilkan laporan bisnis Anda secara
                instan dan akurat. Kami menyediakan berbagai macam format Laporan keuangan yang akan membantu Anda
                menganalisa bisnis lebih tajam lagi</p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-factory"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Pengadan Barang dan Jasa [Procurement Module] </h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Mencatat Permintaan Pembelian (PR) barang lalu
                melanjutkan ke pemilihan vendor yang tepat dengan berbagai pertimbangan yang dapat dilakukan dengan
                bantuan akurat system. Menerbitkan PO dan mengontrol status PR dan juga GRN atas progress penerimaan
                yang terjadi </p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-chart-pie"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Modul Piutang dan Hutang [A/R & A/P Module] </h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Modul lengkap hutang dan piutang dengan berbagai
                laporan Analisa Invoice. Dapatkan Kemudahan untuk mengetahui invoice jatuh tempo, Invice yang telah
                dibayar, umur invoice (aging report), sampai dengan mencetak Surat Pernyataan Piutang / Hutang (SOA)
              </p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-chart-pie"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Laba (Rugi) Selisih Kurs [Forex Gain or Loss]</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Menghitung selisih kurs otomatis akun-akun Riil
                seperti kas, bank, hutang, dan piutang serta akun-akun yang diperlukan lainnya. Sistem akan menghitung
                dan men-jurnal nilai realized dan unrealized (revaluation) dengan membandingkan kurs saat terjadi
                transaksi dan kurs pada saat dibayar/ kurs tutup buku </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="wc-box rounded text-center wc-box-primary p-4 mt-md-5">
              <div class="wc-box-icon">
                <i class="mdi mdi-book-multiple"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Proses Akuntansi Otomatis Dan Ter-integrasi Penuh </h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Proses akuntansi perusahaan akan lebih mudah dengan
                fitur jurnal otomatis di hampir semua transaksi beserta laporan buku besar dan neraca saldo. Pekerjaan
                manual akuntansi relatif tidak diperlukan lagi </p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-currency-usd"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Modul Penjualan [Sales Module]</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Membuat Penawaran Harga , Sales Order, hingga
                menerbitkan Invoice (Faktur) ke Pelanggan. Kami menyediakan berbagai pilihan tampilan Invoice
                professional dengan logo perusahaan. Invoice akan terhubung ke modul Piutang dan Penerimaan Kas pada
                saat proses penerimaan piutang akan dilakukan.</p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-city"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Aktiva Tetap [Fixed Assets]</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Mencatat harga perolehan aktiva tetap dan semua
                informasi penting lainnya seperti supplier, nomor referensi pembelian, lokasi asset, foto asset dan
                sebagainya. Menghitung dan membuat jurnal penyusutan otomatis dengan berbagai metode penyusutan serta
                fitur fixed assets penting lainnya </p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-security"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Security, Authorization, & Audit Trail</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Sistem menyediakan fitur untuk mengatur hak
                otorisasi mengakses atau membatasi modul yang tersedia sesuai dengan hak masing-masing pengguna.
                Selain itu, di setiap transaksi ataupun data master yang tersedia, system akan menampilkan data audit
                trail terkait informasi user yang melakukan entry/ revisi dataserta tanggal dan jam melakukan entry
                atau revisi data</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="wc-box rounded text-center wc-box-primary p-4 mt-md-5">
              <div class="wc-box-icon">
                <i class="mdi mdi-bank"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Modul Lengkap Cash, Cheque & Bank </h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Monitoring secara khusus pengeluaran dan penerimaan
                kas, cheque dan bank. Mencatat tanggal penerbitan dan jatuh tempo cheque, menyiapkan laporan
                rekonsiliasi bank, serta meng-analisa pergerakan kas dan bank dengan beragam laporan analisa menarik
              </p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-package-variant-closed"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Module Persediaan Ter-integrasi [Inventory Module]
              </h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Inventory modul terkoneksi otomatis dengan system
                pembelian, penjualan, serta produksi. Tersedia juga modul transfer stock antar Gudang, Divisi, dan
                Departemen serta modul Stock Opname untuk penyesuaian stock. </p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-autorenew"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Jurnal Berulang & Template Journal [Recurring & Template
                Journal] </h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Memudahkan Anda untuk melakukan setup transaksi yang
                berulang terjadi dan secara otomatis akan dibuat secara otomatis oleh system. Begitu juga untuk jenis
                transaksi yang sama dan berulang. Anda dapat membuat model trasaksi Template untuk dapat digunakan
                kapanpun dibutuhkan </p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-cloud-check"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Cloud Service Support</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Opsi cloud service akan memudahkan Anda menggunakan
                PISICloud dengan biaya relatif murah dan bisa diakses secara online. Dengan opsi cloud, kami
                menyediakan server, software pendukung, dan seluruh kelengkapan lainnya sampai aplikasi siap
                dijalankan. Dan yang lebih penting lagi, semua masalah yang terkait dengan maintenance server,
                database backup, serta hal-hal penting lainnya akan secara otomatis terselesaikan </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>