<app-header></app-header>

<div appScrollspy [spiedTags]="['SECTION']">
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/bg/8.png)" id="home">
    <div class="container">
      <div class="row">
        <div class="text-center">

          <h1 class="mb-3"><b><span class="text-primary">{{'customize.title1' | translate}}</span>{{'customize.title2' |
              translate}}</b></h1>


          <div innerHTML="{{'customize.titleSub' | translate}}"></div>

          <hr style="border: 2px solid #1c735a; width: 20em;">

          <img src="assets/images/customize.jpg" class="img-fluid d-block mx-auto mt-4" width="80%">
        </div>
        <div class="mt-5">
          <p>{{'customize.p1' | translate}}</p>

          <p>{{'customize.p2' | translate}}</p>

          <p>{{'customize.p3' | translate}}</p>

          <p>{{'customize.p4' | translate}}</p>
        </div>
      </div>
    </div>
  </section>

  <!-- Hero End -->

</div>