<section class="hero-1-bg" style="background-image: url(assets/images/bg/6.png)" id="home">
  <div class="container">
    <div class="text-center">
      <h1 class="font-weight-bold mb-4">
        <div innerHTML="{{'solution.distributor.start' | translate}}"></div>
      </h1>
      <p class="font-size-16">
        {{'solution.distributor.startSub' | translate}}
      </p>
      <div class="img-menu">
        <img src="assets/images/solution/distributor.jpg" alt="" class="img-fluid d-block">
      </div>
    </div>
  </div>
</section>

<div style="margin-top: -2em;">
  <app-block-quote1></app-block-quote1>
</div>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/solution/distributor2.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-4 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.distributor.content1' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.distributor.content1Sub' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.distributor.content2' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.distributor.content2Sub' | translate}}
        </p>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="mt-3">
          <img src="assets/images/solution/distributor3.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <app-testimonial></app-testimonial> -->

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/solution/distributor4.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-4 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.distributor.content3' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.distributor.content3Sub' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.distributor.content4' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.distributor.content4Sub' | translate}}
        </p>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="mt-3">
          <img src="assets/images/solution/distributor5.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<app-block-quote2></app-block-quote2>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/solution/distributor6.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-4 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.distributor.content5' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.distributor.content5Sub' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.distributor.content6' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.distributor.content6Sub' | translate}}
        </p>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="mt-3">
          <img src="assets/images/solution/distributor7.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/solution/distributor8.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-4 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.distributor.content7' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.distributor.content7Sub' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.distributor.content8' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.distributor.content8Sub' | translate}}
        </p>
      </div>
      <div class="col-lg-7 offset-lg-1">
        <div class="mt-3">
          <img src="assets/images/solution/distributor9.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>


<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/solution/distributor10.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-4 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.distributor.content9' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.distributor.content9Sub' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/8.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.distributor.content10' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.distributor.content10Sub' | translate}}
        </p>
      </div>
      <div class="col-lg-7 offset-lg-1">
        <div class="mt-3">
          <img src="assets/images/solution/distributor11.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>