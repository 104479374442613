<div class="section bg-light">
  <div class="container-xl" style="margin-top: -3em;">
    <div class="row">
      <div class="col-sm-12">
        <h2 class="font-weight-semibold">
          <div innerHTML="{{'testimonial.title' | translate}}"></div>
        </h2>
        <div id="myCarousel" class="carousel slide" data-ride="carousel">
          <!-- Carousel indicators -->
          <ol class="carousel-indicators">
            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
            <li data-target="#myCarousel" data-slide-to="1"></li>
            <li data-target="#myCarousel" data-slide-to="2"></li>
          </ol>
          <!-- Wrapper for carousel items -->
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-sm-6">
                  <div class="media">
                    <img src="assets/images/testimonial/1.jpg" class="mr-3" alt="">
                    <div class="media-body">
                      <div class="testimonial">
                        <p>Lorem ipsum dolor sit amet, consec adipiscing elit. Nam eusem scelerisque tempor, varius quam
                          luctus dui. Mauris magna metus nec.</p>
                        <p class="overview"><b>Paula Wilson</b>, Media Analyst</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="media">
                    <img src="assets/images/testimonial/2.jpg" class="mr-3" alt="">
                    <div class="media-body">
                      <div class="testimonial">
                        <p>Vestibulum quis quam ut magna consequat faucibus. Pellentesque eget mi suscipit tincidunt.
                          Utmtc tempus dictum. Pellentesque virra.</p>
                        <p class="overview"><b>Antonio Moreno</b>, Web Developer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-6">
                  <div class="media">
                    <img src="assets/images/testimonial/3.jpg" class="mr-3" alt="">
                    <div class="media-body">
                      <div class="testimonial">
                        <p>Lorem ipsum dolor sit amet, consec adipiscing elit. Nam eusem scelerisque tempor, varius quam
                          luctus dui. Mauris magna metus nec.</p>
                        <p class="overview"><b>Michael Holz</b>, Seo Analyst</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="media">
                    <img src="assets/images/testimonial/4.jpg" class="mr-3" alt="">
                    <div class="media-body">
                      <div class="testimonial">
                        <p>Vestibulum quis quam ut magna consequat faucibus. Pellentesque eget mi suscipit tincidunt.
                          Utmtc tempus dictum. Pellentesque virra.</p>
                        <p class="overview"><b>Mary Saveley</b>, Web Designer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-sm-6">
                  <div class="media">
                    <img src="assets/images/testimonial/5.jpg" class="mr-3" alt="">
                    <div class="media-body">
                      <div class="testimonial">
                        <p>Lorem ipsum dolor sit amet, consec adipiscing elit. Nam eusem scelerisque tempor, varius quam
                          luctus dui. Mauris magna metus nec.</p>
                        <p class="overview"><b>Martin Sommer</b>, UX Analyst</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="media">
                    <img src="assets/images/testimonial/6.jpg" class="mr-3" alt="">
                    <div class="media-body">
                      <div class="testimonial">
                        <p>Vestibulum quis quam ut magna consequat faucibus. Pellentesque eget mi suscipit tincidunt.
                          Utmtc tempus dictum. Pellentesque virra.</p>
                        <p class="overview"><b>John Williams</b>, Web Developer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>