<div class="section feather-bg-img" style="background-image: url(assets/images/bg/6.png);" id="features">
    <div class="container mt-5">
      <div class="row">
        <div class="col-lg-5">
          <div class="mb-5 mt-3">
            <p class="font-weight-bold">
              <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> {{'attendanceSoftware.header' | translate}}
            </p>
            <hr style="margin-top: -10px;">
            <h1>{{'attendanceSoftware.title' | translate}}</h1>
            <p class="font-size-16">
              {{'attendanceSoftware.p1' | translate}}
            </p>
          </div>
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-6 mt-5 img-feature">
          <img src="assets/images/features/Attendance 2.png" class="d-block img-fluid">
        </div>
      </div>
  
    </div>
  </div>
  
  
  <section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img.png)">
    <div class="container" style="margin-top: -7em;">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="mt-5">
            <img src="assets/images/features/Attendance 1.png" class="img-fluid d-block w-100">
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1">
          <p class="mb-2 font-size-15">
            {{'attendanceSoftware.p2' | translate}}
          </p>
        </div>
      </div>
    </div>
  </section>
  
  <section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img-1.png)">
    <div class="container" style="margin-top: -5em;">
      <div class="row align-items-center">
        <div class="col-lg-5">
            <p class="mb-2 font-size-15">
                {{'attendanceSoftware.p3' | translate}}
              </p>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="mb-4 mb-lg-0">
            <img src="assets/images/features/Attendance 3.png" class="img-fluid d-block w-100">
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img.png)">
    <div class="container" style="margin-top: -5em;">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="mt-5">
            <img src="assets/images/features/Attendance 7.png" class="img-fluid d-block w-100">
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1">
         
          <div innerHTML="{{'attendanceSoftware.p4' | translate}}"></div>
        </div>
      </div>
    </div>
  </section>
  
  <section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img-1.png)">
    <div class="container" style="margin-top: -2em;">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div innerHTML="{{'attendanceSoftware.p5' | translate}}"></div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="mb-4 mb-lg-0">
            <img src="assets/images/features/Attendance 4.png" class="img-fluid d-block w-100">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img.png)">
    <div class="container" style="margin-top: -5em;">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="mt-5">
            <img src="assets/images/features/Attendance 5.png" class="img-fluid d-block w-100">
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1">
         
          <div innerHTML="{{'attendanceSoftware.p6' | translate}}"></div>
        </div>
      </div>
    </div>
  </section>

  <section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img-1.png)">
    <div class="container" style="margin-top: -2em;">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div innerHTML="{{'attendanceSoftware.p7' | translate}}"></div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="mb-4 mb-lg-0">
            <img src="assets/images/features/Attendance 6.png" class="img-fluid d-block w-100">
          </div>
        </div>
      </div>
    </div>
  </section>
